<template>
  <div class="dashboard">
    <div class="title-section">
      <h1 class="title">
        Dashboard
      </h1>
    </div>
    <section class="content">
      <div class="filters">
        <div class="granularity">
          <b-field
            label="Granularity"
            label-position="on-border"
          >
            <b-radio-button
              v-model="granularity"
              native-value="weekly"
              type="is-primary is-light is-outlined"
            >
              Weekly
            </b-radio-button>

            <b-radio-button
              v-model="granularity"
              native-value="monthly"
              type="is-primary is-light is-outlined"
            >
              Monthly
            </b-radio-button>
          </b-field>
        </div>
        <div
          v-if="granularity === 'weekly'"
          class="weekly-date"
        >
          <div><b>From</b> {{ `${weekStartDate.toISOString().split('T')[0]}` }} <b>to</b> {{ `${weekEndDate.toISOString().split('T')[0]}` }}</div>
          <week-picker v-model="weekSelection" />
        </div>

        <div
          v-if="granularity === 'monthly'"
          class="date"
        >
          <b-field
            label="From - To"
            label-position="on-border"
          >
            <b-datepicker
              v-model="dateRange"
              position="is-bottom-left"
              :min-date="minDate"
              :max-date="maxDate"
              type="month"
              range
              :first-day-of-week="1"
            />
          </b-field>
        </div>
      </div>

      <section>
        <grid
          :columns="columns"
          :data="dashboardData"
          :loading="$apollo.loading"
          :auto-group-column="autoGroupColumnDef"
          @ready="api = $event"
        />
      </section>
    </section>
  </div>
</template>

<script>

import * as moment from 'moment';
import { GET_DASHBOARD_DATA } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import WeekPicker from '../components/WeekPicker';
import CellRendererROAS from '../components/dashboard/CellRendererROAS';

export default {
  components: {
    WeekPicker,
    // eslint-disable-next-line
    CellRendererROAS,
  },
  mixins: [GridMixin],
  data() {
    const today = new Date();
    return {
      weekSelection: `${today.getFullYear()}-W${moment().subtract(1, 'week').isoWeek()}`,
      selectedYear: today.getFullYear(),
      selectedWeek: moment().isoWeek() - 1,
      granularity: 'monthly',
      minDate: new Date(2017, 0, 1),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      dateRange: [
        new Date(today.getFullYear(), today.getMonth() - 2, today.getDate()),
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
      ],
      dashboardData: null,
      weekStartDate: null,
      weekEndDate: null,
    };
  },
  computed: {
    autoGroupColumnDef() {
      return {
        headerName: 'App > Country',
        minWidth: 300,
        field: 'country',
        pinned: 'left',
      };
    },
    columns() {
      return [
        {
          field: 'appName',
          colId: 0,
          headerName: 'App',
          rowGroup: true,
          hide: true,
        },
        {
          field: 'country',
          colId: 1,
          headerName: 'Country',
          filter: true,
          sortable: true,
          pinned: 'left',
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: true,
        },
        {
          field: 'trials',
          colId: 2,
          headerName: 'Trials',
          sortable: true,
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          hide: false,
          aggFunc: 'sum',
        },
        {
          field: 'ltv3yeur',
          colId: 3,
          headerName: 'LTV 3Y (€)',
          sortable: true,
          maxWidth: 160,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          valueFormatter: (val) => this.$options.filters.formatNumber(val.value.ltv3yeur, 1) || '',
          valueGetter: (val) => {
            const ltv3yeur = val.data ? val.data.ltv3yeur : null;
            const trials = val.data ? val.data.trials : null;
            return { ltv3yeur, trials };
          },
          aggFunc: this.avgLtv,
          hide: false,
        },
        {
          field: 'roas3y',
          colId: 4,
          headerName: 'ROAS 3Y (€)',
          sortable: true,
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          aggFunc: this.avgRoas,
          valueGetter: (val) => {
            const roas3y = val.data ? val.data.roas3y : null;
            const revenue3yeur = val.data ? val.data.revenue3yeur : null;
            const spent = val.data ? val.data.spent : null;
            return { roas3y, revenue3yeur, spent };
          },
          cellRendererFramework: 'CellRendererROAS',
          cellRendererParams: {
            ltvDistance: '3y',
          },
          hide: false,
        },
        {
          field: 'revenue3yeur',
          colId: 5,
          headerName: 'Revenue 3Y (€)',
          sortable: true,
          maxWidth: 160,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          valueFormatter: (val) => this.$options.filters.formatNumber(val.value) || '',
          aggFunc: 'sum',
          hide: false,
        },
        {
          field: 'cpa',
          colId: 6,
          headerName: 'CPA (€)',
          sortable: true,
          maxWidth: 160,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          valueFormatter: (val) => this.$options.filters.formatNumber(val.value.cpa, 1) || '',
          valueGetter: (val) => {
            const cpa = val.data ? val.data.cpa : null;
            const trials = val.data ? val.data.trials : null;
            return { cpa, trials };
          },
          aggFunc: this.avgCpa,
          hide: false,
        },
        {
          field: 'spent',
          colId: 7,
          headerName: 'Spent (€)',
          sortable: true,
          maxWidth: 150,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          valueFormatter: (val) => this.$options.filters.formatNumber(val.value) || '-',
          hide: false,
          aggFunc: 'sum',
          sort: 'desc',
        },
      ];
    },
    getDateRange() {
      return this.dateRange;
    },
  },
  watch: {
    weekSelection(input) {
      const [year, week] = input.split('-W');
      this.selectedYear = year;
      this.selectedWeek = week;
    },
  },
  methods: {
    rangeSelectionStarted(date) {
      const startOfWeek = moment(date).startOf('isoWeek').toDate();
      const endOfWeek = moment(date).endOf('isoWeek').toDate();
      this.dateRange = [startOfWeek, endOfWeek];
    },
    avgRoas(params) {
      let totalRevenue3y = 0;
      let totalSpent = 0;
      params.values.forEach((value) => {
        if (value) {
          totalRevenue3y += value.revenue3yeur;
          totalSpent += value.spent;
        }
      });
      return { roas3y: (totalRevenue3y - totalSpent) / (totalSpent > 0 ? totalSpent : 1), revenue3yeur: totalRevenue3y, spent: totalSpent };
    },
    avgLtv(params) {
      let avgLtv3yeur = 0;
      let sumTrials = 0;
      params.values.forEach((value) => {
        if (value && value.ltv3yeur) {
          avgLtv3yeur += value.ltv3yeur * value.trials;
        }
        sumTrials += value.trials;
      });
      return {
        ltv3yeur: (avgLtv3yeur / sumTrials) || 0,
        trials: sumTrials,
      };
    },
    avgCpa(params) {
      let avgCpa = 0;
      let sumTrials = 0;
      params.values.forEach((value) => {
        if (value) {
          avgCpa += value.cpa ? value.cpa * value.trials : 0;
          sumTrials += value.trials || 0;
        }
      });

      const cpa = avgCpa > 0 ? avgCpa / (sumTrials > 0 ? sumTrials : 1) : null;
      return { cpa, trials: sumTrials };
    },
  },
  apollo: {
    dashboardData: {
      query: GET_DASHBOARD_DATA,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        let variables = {
          weekly: this.granularity === 'weekly',
        };
        if (this.getDateRange.length && this.granularity !== 'weekly') {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }

        if (this.weekSelection && this.granularity === 'weekly') {
          const startDate = moment().utc().set('year', this.selectedYear).isoWeek(this.selectedWeek)
            .startOf('isoWeek');
          const endDate = startDate.clone();
          endDate.add(1, 'week').subtract(1, 'second');

          this.weekStartDate = startDate;
          this.weekEndDate = endDate;

          variables = {
            ...variables,
            from: startDate.toISOString().split('T')[0],
            to: endDate.toISOString().split('T')[0],
          };
        }
        return variables;
      },
    },
  },
};

</script>

<style lang="scss" scoped>

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-wrapper {
      flex: 1;
      display: flex;
      max-height: 100%;

    }
  }

  .week-picker ::v-deep .dropdown .datepicker-content .datepicker-body .datepicker-row:hover {
    background: #f2f2f2;

    & .datepicker-cell {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .week-picker .datepicker-content .datepicker-body.datepicker-row {
    background: green;

  }

  .weekly-date {
    display: flex;
    align-items: center;

    & >:first-child {
      margin-right: 10px;
    }
  }

}
</style>
