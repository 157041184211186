<template>
  <div class="cell-template">
    <span
      :class="[ 'roas', predictiveRoas <= -5 ? 'red' : '', predictiveRoas < 0 && predictiveRoas > -5 ? 'orange' : '' ]"
    >
      {{ predictiveRoas }} {{ predictiveRoas ? '%' : '' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CellRenderer',
  data() {
    return {
      predictiveRoas: null,
    };
  },
  beforeMount() {
    if (!this.params.value) {
      return;
    }
    if (this.params.value.roas3y) {
      this.predictiveRoas = Math.round(this.params.value.roas3y * 100);
    }
  },
};
</script>

<style scoped lang="scss">
.cell-template {
  display: flex;
  justify-content: space-between;

  .roas {
    margin-left: 10px;
    width: 40px;
    text-align: right;

    &.red {
      color: red;
    }

    &.orange {
      color: orange;
    }
  }
}
</style>
