<template>
  <div>
    <input
      :value="value"
      type="week"
      @input="handleInput($event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'WeekPicker',
  props: {
    value: {
      type: String,
      required: true,
      default: () => '2022-W01',
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">

input[type='week'] {
  padding: 8px;
  font-size: 16px;
  background-color: white;
  border-radius: 4px;
  color: #363636;
  cursor: pointer;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  border: 1px solid #dbdbdb;
}

</style>
